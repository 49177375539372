import React from "react";

function Signup() {
    return (
        <div>
            <p>Signup</p>
        </div>
    );
}

export default Signup;

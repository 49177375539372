import {Navigate} from 'react-router-dom';
import Application from '.';

// ROUTES
import MainAppRoutes from './main/routes';

const Routes = [
    {
        path: 'app',
        element: <Application />,
        children: [
            ...MainAppRoutes
        ]
    },
    {
        index: true,
        element: <Navigate to="/app/main" replace={true} />
    },
    {
        path: "*",
        element: <Navigate to="/app/main" replace={true} />
    }
];

export default Routes;

import Analytics from '.';

// ROUTES


const Routes = [
    {
        path: 'analytics',
        element: <Analytics />,
        children: [

        ]
    }
];

export default Routes;

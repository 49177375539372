import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StudentListWrapper } from "../../styledComponent/student-list";
import { Divider, Typography } from "@mui/material";
import StudentTable from "./List";
import withReducer from "../../../../../../store/withReducer";
import reducer, { getStudentList } from "./student-list.slice";
import Groups2Icon from "@mui/icons-material/Groups2";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import PageLoader from "../../../components/PageLoader";

function StudentList() {
  const dispatch = useDispatch();
  const { loading, tableData } = useSelector(
    (state: any) => state.student_list
  );

  useEffect(() => {
    if (!tableData?.length || loading) {
      dispatch(getStudentList());
    }
  }, []);

  return (
    <StudentListWrapper>
      <div className="flex flex-row items-start gap-x-4 mb-6">
        <Groups2OutlinedIcon sx={{ fontSize: "60px", color: "#676767" }} />

        <div className="flex flex-col">
          <Typography variant="h3" className="page-heading">
            Students
          </Typography>
          <Typography variant="subtitle1" className="page-subheading">
            All students will be viewable here
          </Typography>
        </div>
      </div>

      <Divider />

      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <div className="list-container">
          <StudentTable />
        </div>
      )}
    </StudentListWrapper>
  );
}

export default withReducer("student_list", reducer)(React.memo(StudentList));

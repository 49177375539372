import React from "react";
import clsx from "clsx";
import { DetailsWrapper } from "../../styledComponent/student-details";
import { Chip, Divider, Paper, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

interface Props {
  report: any;
}

function CareerReportComponent({ report }: Props) {
  console.log(report);

  return (
    <DetailsWrapper>
      <div className={clsx("flex flex-col justify-start items-stretch")}>
        <div
          className={clsx(
            "flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 report-container"
          )}
        >
          <Paper
            elevation={3}
            className={clsx("flex flex-col report-card-score")}
          >
            <Typography variant="body1">Your Score</Typography>
            <div className={clsx("flex flex-row justify-start items-end")}>
              <Typography variant="h1">{report.score}</Typography>
              <div className={"mb-4"}>
                <Typography variant="h5">/{report.maxScore}</Typography>
              </div>
            </div>

            <div className={clsx("flex flex-row space-x-3")}>
              <CircleIcon sx={{ color: report?.remark?.color }} />
              <Typography variant="subtitle1">
                {report?.remark?.remark}
              </Typography>
            </div>
          </Paper>

          <Paper
            elevation={3}
            className={clsx(
              "flex flex-col justify-start items-stretch report-card-score"
            )}
          >
            <Typography variant="body1">Aligned Subjects</Typography>

            <div className={clsx("w-full flex flex-row flex-wrap")}>
              {report?.alignedSubjects.map((subject: any) => (
                <div className={"m-1"}>
                  <Chip label={subject} />
                </div>
              ))}
            </div>
          </Paper>
        </div>

        <div
          className={clsx(
            "flex flex-col justify-start items-stretch detailed-report-container"
          )}
        >
          <Typography variant="h5">Your detailed report</Typography>

          <div
            className={clsx(
              "flex flex-col justify-start items-stretch mt-8 space-y-6"
            )}
          >
            {report?.sections?.map((section: any) => (
              <div
                className={clsx(
                  "flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8"
                )}
              >
                <Paper
                  elevation={3}
                  className={clsx(
                    "flex flex-col justify-start items-stretch section-score-card"
                  )}
                >
                  <Typography variant="h5">{section?.sectionName}</Typography>
                  <div
                    className={clsx("flex flex-row justify-start items-end")}
                  >
                    <Typography variant="h1">
                      {section?.sectionScore}
                    </Typography>
                    <div className={"mb-4"}>
                      <Typography variant="h5">
                        /{section?.sectionMaxScore}
                      </Typography>
                    </div>
                  </div>
                </Paper>

                <Paper
                  elevation={3}
                  className={clsx(
                    "flex flex-col justify-start items-stretch section-remark-card"
                  )}
                >
                  <Typography variant="subtitle1">Remark</Typography>

                  <div
                    className={clsx(
                      "flex flex-row justify-start items-center space-x-3"
                    )}
                  >
                    <CircleIcon sx={{ color: section?.sectionRemark?.color }} />
                    <Typography variant="h6">
                      {section?.sectionRemark?.remark}
                    </Typography>
                  </div>
                </Paper>

                <Divider />
              </div>
            ))}
          </div>
        </div>
      </div>
    </DetailsWrapper>
  );
}

export default CareerReportComponent;

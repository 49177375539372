import React from "react";
import { DetailsWrapper } from "../../styledComponent/student-details";
import { Typography } from "@mui/material";

function Details({ studentMeta }: any) {
  return (
    <DetailsWrapper>
      <div className="grid grid-cols-4 gap-4">
        <div className="grid-item">
          <Typography variant="body2">Student Id</Typography>
          <Typography variant="h5">{studentMeta?._id}</Typography>
        </div>
        <div className="grid-item">
          <Typography variant="body2">Username</Typography>
          <Typography variant="h5">{studentMeta?.username}</Typography>
        </div>
        <div className="grid-item">
          <Typography variant="body2">Password</Typography>
          <Typography variant="h5">{studentMeta?.password}</Typography>
        </div>
        <div className="grid-item">
          <Typography variant="body2">Roll Number</Typography>
          <Typography variant="h5">-</Typography>
        </div>
      </div>
    </DetailsWrapper>
  );
}

export default Details;

import React from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";
import Navigation from "./components/navigation";
import AppToolbar from "./components/toolbar";

function MainApp() {
  return (
    <div
      className={clsx(
        "w-full flex flex-row justify-start items-stretch overflow-x-hidden"
      )}
    >
      <Navigation />

      <div className={"w-full flex flex-col justify-start items-stretch"}>
        <AppToolbar />

        <div className={"h-full flex flex-col justify-start items-stretch"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default MainApp;

import React from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";
import { Typography } from "@mui/material";

function ClassModule() {
  return (
    <div className={clsx("flex flex-col justify-center items-center gap-y-4")}>
      <div className="flex flex-col justify-center items-center text-center">
        <Typography variant="h3">Classrooms</Typography>
        <Typography variant="h6">
          Your School classrooms will be here, you will be able to view, create
          and edit, assign teachers, organize students, assign careers to each
          classrooms and much more actions
        </Typography>
      </div>

      <img src="/assets/classrooms.png" style={{ height: "70vh" }} />
    </div>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default ClassModule;

import { styled } from "@mui/system";

export const StudentDetailsWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "40px 30px",
}));

export const DetailsWrapper = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  margin: "20px 0px",
  padding: "4px",

  ".page-heading": {
    lineHeight: "1",
  },

  ".page-subheading": {
    lineHeight: "1",
    paddingTop: "1px",
    paddingLeft: "4px",
    color: "#676767",
  },

  ".report-container": {
    margin: "30px 0px",
  },
  ".report-card-score": {
    padding: "20px 30px",
    width: "300px",
    height: "250px",
  },
  ".report-card-subjects": {
    padding: "20px 30px",
    width: "450px",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  ".detailed-report-container": {
    marginTop: "30px",
  },
  ".section-score-card": {
    padding: "20px 30px",
    width: "300px",
    height: "200px",
  },
  ".section-remark-card": {
    padding: "20px 30px",
    width: "500px",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const CareerCoursesListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0px",
}));

const DEFAULT_AWS_AUTH = {
    Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_2xodL1PCU',
        userPoolWebClientId: '5e8ohso5ef9cm2h7ihlmk5qd76'
    }
};

const DEV_AWS_AUTH = {
    Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_hIXeerkpA',
        userPoolWebClientId: '5g1kd1q5n8hmolcb4fjcb00i86',
        oauth: {
            domain: 'dev-careerconnectspassion.auth.ap-south-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/auth/oauth/google/success,https://dev.careerconnectspassion.com/auth/oauth/google/success',
            redirectSignOut: 'http://localhost:3000/,https://dev.careerconnectspassion.com/',
            responseType: 'token'
        }
    }
};
const STAGE_AWS_AUTH = {
    Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_2xodL1PCU',
        userPoolWebClientId: '5e8ohso5ef9cm2h7ihlmk5qd76',
        oauth: {
            domain: 'stage-careerconnectspassion.auth.ap-south-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/auth/oauth/google/success,https://stage.careerconnectspassion.com/auth/oauth/google/success',
            redirectSignOut: 'http://localhost:3000/,https://stage.careerconnectspassion.com/',
            responseType: 'token'
        }
    }
};
const PROD_AWS_AUTH = {
    Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_83QnwEQ48',
        userPoolWebClientId: '3o9foifuovlm5loceradql9e6j',
        oauth: {
            domain: 'prod-careerconnectspassion.auth.ap-south-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/auth/oauth/google/success,https://www.careerconnectspassion.com/auth/oauth/google/success',
            redirectSignOut: 'http://localhost:3000/,https://www.careerconnectspassion.com/',
            responseType: 'token'
        }
    }
};

const getAuthConfig = () => {
    switch(process.env.REACT_APP_ENVIRONMENT) {
        case "dev":
            return DEV_AWS_AUTH
        case "stage":
            return STAGE_AWS_AUTH
        case "prod":
            return PROD_AWS_AUTH
        default:
            return DEFAULT_AWS_AUTH
    }
};

export default getAuthConfig();

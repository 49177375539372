import { createSlice, createAsyncThunk, createEntityAdapter, current  } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getStudentList = createAsyncThunk('student/getStudentList', (params, { dispatch, getState, rejectWithValue}) => {
    dispatch(setLoading(true));
    return axios.get(`/campus/students`).then(response => {
        dispatch(setTableData(response?.data?.data))
        dispatch(setLoading(false));
    }).catch(error => rejectWithValue(error.response));
});

// ENTITY ADAPTER
// const studentsAdapter = createEntityAdapter({});
//
// export const { selectAll: selectCareer, selectById: selectCareerById } = studentsAdapter.getSelectors(
//     state => state.Career.careers
// );

// const initialState = studentsAdapter.getInitialState({
//     loading: true,
//     tableData: []
// });

const initialState = {
    loading: true,
    tableData: []
};

// SLICE
const studentsSlice = createSlice({
    name: 'studentList',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        },
        setTableData: {
            reducer: (state, action) => {
                state.tableData = action.payload
            }
        }
    },
    // extraReducers: {
    //     [getStudentList.fulfilled]: (state, action) => {
    //         const {status, data} = action.payload;
    //         if(status === 200 && data) {
    //             state.tableData = data?.data;
    //             state.loading = false;
    //         }
    //     }
    // }
});

// ACTIONS
export const { setLoading, setTableData } = studentsSlice.actions;

// REDUCERS
export default studentsSlice.reducer;

import React from "react";
import clsx from 'clsx';
import { Outlet } from "react-router-dom";

function Application() {
    return (
        <div className={clsx("h-screen w-screen flex flex-row justify-start items-stretch")}>
            <Outlet />
        </div>
    );
}

// export default withReducer('student', reducer)(StudentApp);
export default Application;

import { combineReducers } from '@reduxjs/toolkit';
import app from './app';
import auth from  '../auth/store'

const createReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        app,
        auth,
        ...asyncReducers
    });

    /*
    Reset the redux store when user logged out
     */
    if (action.type === 'auth/user/userLoggedOut') {
        state = undefined;
    }

    return combinedReducer(state, action);
};

export default createReducer;

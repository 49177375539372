import { styled } from '@mui/system';

export const StudentListWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '40px 30px',

    '.page-heading': {
        lineHeight: '1'
    },

    '.page-subheading': {
        lineHeight: '1',
        paddingTop: '1px',
        paddingLeft: '4px',
        color: '#676767'
    },

    '.list-container': {
        margin: '20px 0px'
    }
}));


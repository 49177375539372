// import { ThemeOptions } from '@mui/material';
/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
    typography: {
        fontFamily: [
            "Varela Round",
            'sans-serif'
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    custom: {
        border: {
            primary: {
                main: "2px solid #ccccccc2",
                radius: "8px"
            }
        }
    }
};

export const defaultThemes = {
    default: {
        ...defaultThemeOptions,
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#70CDE0',
                border: '#ccccccc2'
            },
            secondary: {
                main: '#FFC860',
            },
            accent: {
                main: '#FE7062'
            },
            warn: {
                main: '#ffa726',
                dark: '#f57c00',
                light: '#ffb74d'
            },
            error: {
                main: '#f44336',
                dark: '#d32f2f',
                light: '#e57373'
            }
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: `"Varela Round", sans-serif`
                },
            },
            MuiButton: {
                variants: [
                    // CONTAINED
                    {
                        props: { variant: 'contained' },
                        style: {
                            textTransform: 'none',
                            color: 'white',
                            letterSpacing: '1.5px',
                            '&:hover': {
                                color: 'white'
                            }
                        }
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                            textTransform: 'none',
                            letterSpacing: '1.5px',
                        }
                    },
                    //TEXT
                    {
                        props: { variant: 'text' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            // color: '#70CDE0',
                            textTransform: 'none',
                            letterSpacing: '1px',
                        }
                    },
                    // OUTLINE
                    // {
                    //     props: { variant: 'outline' },
                    //     style: {
                    //         textTransform: 'none',
                    //     },
                    // },
                    //
                    // CUSTOM PAGE - CUSTOM BUTTONS
                    {
                        props: { variant: 'outline-flat' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            color: '#424242',
                            textTransform: 'none',
                            border: '1px solid #424242',
                            letterSpacing: '1.5px',
                        },
                    },
                    {
                        props: { variant: 'outline-raised' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            fontWeight: 'bold',
                            color: '#565656',
                            textTransform: 'none',
                            border: '1px solid #565656',
                            letterSpacing: '1px',
                            boxShadow: '0 8px 16px 0 rgb(140 34 34 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
                        },
                    },
                    {
                        props: { variant: 'grey-button' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            color: '#9e9e9e',
                            backgroundColor: '#e5e5e5',
                            textTransform: 'none',
                            letterSpacing: '1.5px',
                            '&:hover': {
                                backgroundColor: '#e5e5e5',
                                color: 'black',
                            }
                        },
                    },
                    {
                        props: { variant: 'facebook' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            color: '#4267B2',
                            textTransform: 'none',
                            border: '1.6px solid #4267B2',
                            letterSpacing: '1.5px',
                        },
                    },
                    {
                        props: { variant: 'google' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            color: '#DB4437',
                            textTransform: 'none',
                            border: '1.6px solid #DB4437',
                            letterSpacing: '1.5px',
                        },
                    },
                    {
                        props: { variant: 'master-button' },
                        style: {
                            fontFamily: `"Varela Round", sans-serif`,
                            color: 'white',
                            // height: '55px',
                            backgroundColor: '#FE7062',
                            textTransform: 'none',
                            letterSpacing: '1.5px',
                            '&:hover': {
                                backgroundColor: '#FE7062',
                                color: 'white',
                            }
                        },
                    },
                ],
            },
            MuiTextField: {
                defaultProps: {
                    size: "small"
                },
                variants: [
                    {
                        props: { variant: 'outlined' }
                    }
                ]
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginTop: 0,
                        height: 0,
                    }
                }
            }
        }
    },
    defaultDark: {
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: 'blue',
            },
            secondary: {
                // This is green.A700 as hex.
                main: '#11cb5f',
            },
        },
        status: {
            danger: 'orange'
        }
    }
};

import React, { useEffect } from "react";
import { Divider, Typography } from "@mui/material";

import { CourseDetailsWrapper } from "../../styledComponent/course-details";
import CareerReportComponent from "./CareerReport";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import withReducer from "../../../../../../store/withReducer";
import reducer, { getStudentCareerReport } from "./career-report.slice";
import PageLoader from "../../../components/PageLoader";

function CareerReport() {
  const dispatch = useDispatch();
  const { id, studentUserUuid, careerId } = useParams();

  const { loading, careerReport } = useSelector(
    (state: any) => state.student_career_report
  );
  const { career, report } = careerReport || {};

  useEffect(() => {
    // @ts-ignore
    dispatch(getStudentCareerReport({ studentUserUuid, careerId }));
  }, []);

  return (
    <CourseDetailsWrapper>
      {loading ? (
        <PageLoader loading={loading} />
      ) : (
        <>
          <div className="flex flex-row items-start gap-x-4 mb-6">
            <img src={career?.logo} style={{ width: "40px", height: "40px" }} />

            <div className="flex flex-col">
              <Typography variant="subtitle2" className="page-subheading">
                Career Report
              </Typography>
              <Typography variant="h4" className="page-heading">
                {career?.name}
              </Typography>
            </div>
          </div>

          <Divider />

          <CareerReportComponent report={report} />
        </>
      )}
    </CourseDetailsWrapper>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default withReducer("student_career_report", reducer)(CareerReport);

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

interface Props {
  loading: boolean;
  style?: any;
}

function PageLoader({ loading, style }: Props) {
  let loaderStyle = style ? style : { height: "50%", width: "100%" };

  return (
    <div
      className="page-loader flex flex-col justify-center items-center"
      style={loaderStyle}
    >
      <CircularProgress />
    </div>
  );
}

export default PageLoader;

import ClassModule from '.';

// ROUTES


const Routes = [
    {
        path: 'class',
        element: <ClassModule />,
        children: [

        ]
    }
];

export default Routes;

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  current,
} from "@reduxjs/toolkit";
import axios from "axios";
import { setLoading, setTableData } from "../student-list/student-list.slice";

// ASYNC THUNK OPERATIONS
export const getStudentDetails = createAsyncThunk(
  "student/getStudentDetails",
  (params, { dispatch, getState, rejectWithValue }) => {
    dispatch(setDetailsLoading(true));
    return axios
      .get(`/campus/student/${params.id}`)
      .then((response) => {
        dispatch(setDetails(response?.data?.data));
        dispatch(setDetailsLoading(false));
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getStudentCareers = createAsyncThunk(
  "student/getStudentCareers",
  (params, { dispatch, getState, rejectWithValue }) => {
    dispatch(setCareersLoading(true));
    return axios
      .get(`/campus/student/${params.id}/careers`)
      .then((response) => {
        dispatch(setCareers(response?.data?.data));
        dispatch(setCareersLoading(false));
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

// ENTITY ADAPTER
// const studentsAdapter = createEntityAdapter({});
//
// export const { selectAll: selectCareer, selectById: selectCareerById } = studentsAdapter.getSelectors(
//     state => state.Career.careers
// );

// const initialState = studentsAdapter.getInitialState({
//     loading: true,
//     tableData: []
// });

const initialState = {
  detailsLoading: true,
  details: [],
  careersLoading: true,
  careers: [],
};

// SLICE
const studentDetailsSlice = createSlice({
  name: "studentDetails",
  initialState,
  reducers: {
    setDetailsLoading: {
      reducer: (state, action) => {
        state.detailsLoading = action.payload;
      },
    },
    setCareersLoading: {
      reducer: (state, action) => {
        state.careersLoading = action.payload;
      },
    },
    setDetails: {
      reducer: (state, action) => {
        state.details = action.payload;
      },
    },
    setCareers: {
      reducer: (state, action) => {
        state.careers = action.payload;
      },
    },
  },
  // extraReducers: {
  //     [getStudentDetails.fulfilled]: (state, action) => {
  //         const {status, data} = action.payload;
  //         if(status === 200 && data) {
  //             state.careers = data?.data;
  //             state.loading = false;
  //         }
  //     }
  // }
});

// ACTIONS
export const { setDetailsLoading, setCareersLoading, setDetails, setCareers } =
  studentDetailsSlice.actions;

// REDUCERS
export default studentDetailsSlice.reducer;

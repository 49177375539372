import { Navigate } from "react-router-dom";
import MainApp from '.';

// ROUTES
import DashboardRoutes from './dashboard/routes'
import AnalyticsRoutes from './analytics/routes';
import StudentRoutes from './student/routes';
import ClassRoutes from './class/routes';

const Routes = [
    {
        path: 'main',
        element: <MainApp />,
        children: [
            {
                index: true,
                element: <Navigate to="/app/main/dashboard" replace={true} />
            },
            ...DashboardRoutes,
            ...AnalyticsRoutes,
            ...StudentRoutes,
            ...ClassRoutes
        ]
    },
    {
        index: true,
        element: <Navigate to="/app/main/dashboard" replace={true} />
    },
    {
        path: "*",
        element: <Navigate to="/app/main/dashboard" replace={true} />
    }
];

export default Routes;

import React from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";
import { Typography } from "@mui/material";

function Dashboard() {
  return (
    <div className={clsx("flex flex-col justify-center items-center gap-y-4")}>
      <div className="flex flex-col justify-center items-center">
        <Typography variant="h3">Dashboard</Typography>
        <Typography variant="h6">
          Your dashboard is under-development, you can customize after
          development
        </Typography>
      </div>

      <img src="/assets/dashboard.png" style={{ height: "70vh" }} />
    </div>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default Dashboard;

import Dashboard from '.';

// ROUTES


const Routes = [
    {
        path: 'dashboard',
        element: <Dashboard />,
        children: [

        ]
    }
];

export default Routes;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {createSearchParams, useNavigate} from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import {AuthWrapper} from "../styledComponents/style";
import { StyledCompanyLogo } from "../../styledComponents/globalStyle";
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import { submitLogin, resetLoginStatus } from '../../../auth/store/login.slice';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginStatus = useSelector((state: RootStateOrAny) => state.auth.login);

    // STATES
    const [loading, setLoading] = useState(false);

    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    const handleLogin = () => {
        if(isValid && isDirty) {
            setLoading(true);
            dispatch(submitLogin({username: form.username, password: form.password}));
        }
    };

    useEffect(() => {
        if(loginStatus.success){
            dispatch(showSnackbar({
                message: "You are successfully logged in",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'success'
            }));

            navigate('/app/main');

            setLoading(false);
        } else if(loginStatus.errors.length) {
            const loginError = loginStatus.errors[0];

            if(loginError.code === "UserNotConfirmedException") {
                navigate({
                    pathname: `../confirm/${form.username}`,
                    search: `?${createSearchParams({
                        state: "old"
                    })}`
                });

                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'info'
                }));
            } else {
                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));
            }
            setLoading(false);
        }
    }, [loginStatus]);

    return (
        <AuthWrapper>
            {/*<img src="/assets/ccp-login.png" className="ccp-login-img" />*/}

            <div className={"flex flex-col auth-container"}>
                <div className={"flex flex-row justify-center items-center space-x-6 auth-header"}>
                    <StyledCompanyLogo />
                    <AddIcon sx={{fontSize: "60px"}} />
                    <SchoolIcon sx={{fontSize: "100px"}} />
                </div>

                <div className={"flex flex-col justify-center items-center auth-input-container"}>
                    <div className={"flex flex-col justify-center items-stretch space-y-6 w-3/4"}>
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Username is required" }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className="mt-8 mb-16 w-12/25"
                                    error={!!errors.username}
                                    required
                                    // helperText={errors?.username?.message}
                                    label="Username"
                                    id="username"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Password is required" }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    type="password"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className="mt-8 mb-16 w-12/25"
                                    error={!!errors.password}
                                    required
                                    // helperText={errors?.password?.message}
                                    label="Password"
                                    id="password"
                                    variant="outlined"
                                />
                            )}
                        />

                        <LoadingButton variant="contained" onClick={() => handleLogin()} loading={loading}>Login</LoadingButton>
                    </div>
                </div>
            </div>
        </AuthWrapper>
    );
}

export default Login;

import { createTheme, responsiveFontSizes, } from '@mui/material/styles';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
    defaultThemes
} from "../../theme/settings";

// GENERATOR
function generateMuiTheme() {
    return responsiveFontSizes(createTheme(defaultThemes.default));
}

// SELECTORS
export const selectMainTheme = createSelector([], () =>
    generateMuiTheme()
);

const initialState = {
    settings: defaultThemes
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {

    }
});

export const {  } = themeSlice.actions;

export default themeSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Menu,
  Typography,
  MenuItem,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { StudentDetailsWrapper } from "../../styledComponent/student-details";
import Details from "./Details";
import CareerCoursesList from "./CareerCoursesList";
import withReducer from "../../../../../../store/withReducer";
import reducer, {
  getStudentCareers,
  getStudentDetails,
} from "./student-details.slice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import PageLoader from "../../../components/PageLoader";
import Classrooms from "./Classrooms";
import EnrolledCareers from "./EnrolledCareers";

function StudentDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { detailsLoading, careersLoading, details, careers } = useSelector(
    (state: any) => state.student_data
  );

  useEffect(() => {
    // @ts-ignore
    dispatch(getStudentDetails({ id }));
    // @ts-ignore
    dispatch(getStudentCareers({ id }));
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StudentDetailsWrapper>
      {detailsLoading ? (
        <PageLoader loading={detailsLoading} style={{ height: "40vh" }} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-row items-start gap-x-4 mb-6">
              <PersonIcon sx={{ fontSize: "60px", color: "#676767" }} />

              <div className="flex flex-col">
                <div className="flex flex-row">
                  <Typography
                    variant="subtitle2"
                    className="page-subheading mr-8"
                  >
                    Student
                  </Typography>
                  <Tooltip title="Student data migration is under-development, there will me all customizable fields here and student data which he/she enters in their accounts, and all data can be saved for a student">
                    <InfoIcon
                      sx={{
                        fontSize: "20px",
                        color: "rgb(255, 200, 96)",
                        marginLeft: "4px",
                      }}
                    />
                  </Tooltip>
                </div>

                <Typography variant="h4" className="page-heading">
                  {details?.studentMeta?.username}
                </Typography>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <Tooltip title="This will be admin actions on student, they can block, notify, edit or whatever actions they want to add in future for or on students account">
                <InfoIcon
                  sx={{
                    fontSize: "20px",
                    color: "rgb(255, 200, 96)",
                    marginRight: "4px",
                  }}
                />
              </Tooltip>

              <Button
                variant="outlined"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Action
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>Block</MenuItem>
                <MenuItem onClick={handleClose}>Edit</MenuItem>
                <MenuItem onClick={handleClose}>Notify</MenuItem>
              </Menu>
            </div>
          </div>

          <Details studentMeta={details?.studentMeta} />

          <Divider />

          <Classrooms classrooms={details?.studentClassrooms} />
        </>
      )}

      <Divider />

      {careersLoading ? (
        <PageLoader loading={detailsLoading} style={{ height: "40vh" }} />
      ) : (
        <div>
          <EnrolledCareers careers={careers?.enrolledCareers} />
          <CareerCoursesList
            careers={careers?.activeClassroomsCareers}
            studentUserUuid={careers?.userUuid}
          />
        </div>
      )}
    </StudentDetailsWrapper>
  );
}

export default withReducer("student_data", reducer)(StudentDetails);

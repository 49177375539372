import React from "react";
import clsx from 'clsx';
import { Outlet } from "react-router-dom";

function Auth() {
    console.log("authentication")
    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-center items-stretch")}>
            <Outlet/>
        </div>
    );
}

export default Auth;

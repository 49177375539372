import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import ClassIcon from '@mui/icons-material/Class';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';

export const navigation = [
    {
        title: "Dashboard",
        icon: <DashboardOutlinedIcon />,
        activeIcon: <DashboardIcon />,
        navigateTo: "dashboard"
    },
    {
        title: "Analytics",
        icon: <InsightsOutlinedIcon />,
        activeIcon: <InsightsIcon />,
        navigateTo: "analytics"
    },
    {
        title: "Class",
        icon: <ClassOutlinedIcon />,
        activeIcon: <ClassIcon />,
        navigateTo: "class"
    },
    {
        title: "Student",
        icon: <Groups2OutlinedIcon />,
        activeIcon: <Groups2Icon />,
        navigateTo: "student"
    }
]

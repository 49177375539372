import { combineReducers } from '@reduxjs/toolkit';
import login from './login.slice';
import user from './user.slice';

const authReducers = combineReducers({
    user,
    login
});

export default authReducers;

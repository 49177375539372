import Student from ".";

// ROUTES
import StudentList from "./components/student-list";
import StudentDetails from "./components/student-details";
import CareerReport from "./components/career-report";

const Routes = [
  {
    path: "student",
    element: <Student />,
    children: [
      {
        index: true,
        element: <StudentList />,
      },
      {
        path: ":id",
        element: <StudentDetails />,
      },
      {
        path: ":id/:studentUserUuid/career/:careerId",
        element: <CareerReport />,
      },
    ],
  },
];

export default Routes;

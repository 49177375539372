import type { RouteObject } from 'react-router-dom';
import AuthRoutes from "../main/auth/routes";
import ApplicationRoutes from "../main/app/routes";

const routes: RouteObject[] = [
    ...AuthRoutes,
    ...ApplicationRoutes
];

export default routes;

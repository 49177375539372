// import SplashScreen from '@app/layout/shared/splash.component';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
// import { hideSnackbar, showSnackbar } from '@app/store/app/snackbar.slice';
import AuthService from '../services/auth/auth';
import { setUserData } from "./store/user.slice";

class Auth extends Component {
    state = {
        waitAuthCheck: true
    };

    componentDidMount() {
        return Promise.all([
            this.authCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false });
        });
    }

    authCheck = () =>
        new Promise((resolve) => {
            AuthService.on('onAutoLogin', () => {
                /**
                 * Sign in and retrieve user data from Api
                 */
                AuthService
                    .signInWithToken()
                    .then((user) => {
                        // this.props.setUserData(user);
                        // this.props.getUserCart();

                        resolve();

                        // this.props.showSnackbar({ message: 'Logged in with JWT' });
                    })
                    .catch((error) => {
                        // this.props.showSnackbar({ message: error.message });

                        resolve();
                    });
            });

            AuthService.on('onAutoLogout', (message) => {
                // if (message) {
                //     this.props.showMessage({ message });
                // }
                //
                // this.props.logout();

                console.log("Auto Logout");

                resolve();
            });

            AuthService.on('onNoAccessToken', () => {
                console.log("No Access Token");
                resolve();
            });

            AuthService.init();

            return Promise.resolve();
        });

    render() {
        return this.state.waitAuthCheck ? <div>Loading...</div> : <>{this.props.children}</>;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setUserData,
            // showSnackbar,
            // hideSnackbar
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(Auth);

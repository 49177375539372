import Auth from ".";
import Login from "./components/Login";
import Signup from "./components/Signup";

const routes = [
    {
        path: '/',
        element: <Auth />,
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                path: 'signup',
                element: <Signup />
            }
        ]
    }
];

export default routes;

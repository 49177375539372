import React from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";
import { Typography } from "@mui/material";

function Analytics() {
  return (
    <div className={clsx("flex flex-col justify-center items-center gap-y-4")}>
      <div className="flex flex-col justify-center items-center text-center">
        <Typography variant="h3">Analytics</Typography>
        <Typography variant="h6">
          Your whole schoole analytics will be here, charts, table for careers
          enrolled, assignment and engagement, you can create multiple charts
          according to your measures and dimensions
        </Typography>
      </div>

      <img src="/assets/analytics.png" style={{ height: "70vh" }} />
    </div>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default Analytics;

import React from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";

function Student() {
  return (
    <div className={clsx("flex flex-row justify-start items-stretch")}>
      <Outlet />
    </div>
  );
}

// export default withReducer('student', reducer)(StudentApp);
export default React.memo(Student);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    state: null,
    options: {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
        },
        autoHideDuration: 6000,
        message: null,
        variant: null
    }
};
const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (state, action) => {
            state.state = true;
            state.options = {
                ...initialState.options,
                ...action.payload
            };
        },
        hideSnackbar: (state, action) => {
            state.state = null;
            state.options.message = null;
        }
    }
});

export const { hideSnackbar , showSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;

import { styled } from '@mui/system';

export const CourseDetailsWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '40px 30px',
}));

export const DetailsWrapper = styled('div')(({theme}) => ({
    margin: "20px 0px"
}))

export const CareerCoursesListWrapper = styled('div')(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    margin: "20px 0px"
}))


import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  current,
} from "@reduxjs/toolkit";
import axios from "axios";

// ASYNC THUNK OPERATIONS
export const getStudentCareerReport = createAsyncThunk(
  "student/getStudentCareerReport",
  (params, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    return axios
      .get(
        `/campus/student/${params.studentUserUuid}/career/${params.careerId}/report`
      )
      .then((response) => {
        dispatch(setCareerReport(response?.data?.data));
        dispatch(setLoading(false));
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

// ENTITY ADAPTER
// const studentsAdapter = createEntityAdapter({});
//
// export const { selectAll: selectCareer, selectById: selectCareerById } = studentsAdapter.getSelectors(
//     state => state.Career.careers
// );

// const initialState = studentsAdapter.getInitialState({
//     loading: true,
//     tableData: []
// });

const initialState = {
  loading: true,
  careerReport: {},
};

// SLICE
const studentCareerReport = createSlice({
  name: "studentCareerReport",
  initialState,
  reducers: {
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
    setCareerReport: {
      reducer: (state, action) => {
        state.careerReport = action.payload;
      },
    },
  },
  // extraReducers: {
  //     [getStudentDetails.fulfilled]: (state, action) => {
  //         const {status, data} = action.payload;
  //         if(status === 200 && data) {
  //             state.careers = data?.data;
  //             state.loading = false;
  //         }
  //     }
  // }
});

// ACTIONS
export const { setLoading, setCareerReport } = studentCareerReport.actions;

// REDUCERS
export default studentCareerReport.reducer;

import { memo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { selectMainTheme } from '../store/app/theme.slice';

function Theme(props) {
    const mainTheme = useSelector(selectMainTheme);

    return (
        <ThemeProvider theme={mainTheme}>
            {props.children}
        </ThemeProvider>
    )
}

export default memo(Theme);

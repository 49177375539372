import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  axios.defaults.baseURL =
    "https://mkeqbckyqb.execute-api.ap-south-1.amazonaws.com/prod";
} else {
  axios.defaults.baseURL =
    "https://m0iiu661l9.execute-api.ap-south-1.amazonaws.com/stage";
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { styled } from '@mui/system';

export const AuthWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: "100vh",
    background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",

    ".ccp-login-img": {
        width: "55%"
    },

    ".auth-container": {
        background: "white",
        width: '460px',
        height: '80vh',
        border: theme?.custom.border.primary.main,
        borderRadius: theme?.custom.border.primary.radius
    },

    ".auth-header": {
        height: "30%"
    },

    ".auth-input-container": {
        height: "60%"
    }
}));


import { combineReducers } from '@reduxjs/toolkit';
import theme from './theme.slice';
import snackbar from './snackbar.slice';

const appReducers = combineReducers({
    theme,
    snackbar
});

export default appReducers;
